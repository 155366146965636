<template>
  <section v-if="ganadorEncabezado">
    <v-row>
      <v-col cols="12">
        <p class="secondary--text mb-0 headline">Ofertas presentadas</p>
        <P class="secondary--text mb-0">{{itemsGenerales.codigo_proceso}}</P>
        <p class="secondary--text mb-0">{{itemsGenerales.nombre_proceso}}</p>
        <p class="secondary--text mb-0">{{itemsGenerales.forma_contratacion}}</p>
      </v-col>
      <v-col cols="12" class="d-flex justify-center">
        <v-card color="bgMinsal" elevation="0">
          <v-card-title class="d-flex flex-column justify-center">
            <p style="font-size: 15px">
              {{ ganadorEncabezado.nombre_comercial }}
            </p>
            <p class="mt-n6 subtitle-1 text-center text-wrap">
              <span class="mr-3"
                >Precio unitario: ${{
                  Number(ganadorEncabezado?.precio_unitario).toFixed(2)
                }}</span
              >
              <span
                >Total: ${{ Number(ganadorEncabezado?.total).toFixed(2) }}</span
              >
            </p>
          </v-card-title>
          <v-progress-linear rounded value="100" class="mt-n8" color="btnRed" />
        </v-card>
      </v-col>
      <v-col cols="12">
        <p class="mb-0">
          Canitdad: {{ ganadorEncabezado?.cantidad }}
          {{ ganadorEncabezado?.unidad_medida }}
        </p>
        <p class="mb-0">
          Especificación: {{ ganadorEncabezado?.nombre_agrupacion }}
        </p>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="12" md="6">
            <p
              class="text-h6 mb-0 d-block"
              :class="$vuetify.breakpoint.xs ? 'text-center' : ''"
            >
              Investigación
            </p>
            <v-row>
              <v-col cols="12">
                <p block class="text-center mb-0 secondary--text">
                  Precio promedio de mercado
                </p>
                <p block class="text-h6 secondary--text mb-0 mt-0 text-center">
                  $
                  {{
                    fixedNumber(
                      investigacion?.data?.montosInvestigacion[0]
                        ?.precio_promedio
                    )
                  }}
                </p>
              </v-col>
              <v-col cols="12" class="d-flex justify-center mb-0">
                <v-chip outlined>
                  $
                  {{
                    fixedNumber(
                      investigacion?.data?.montosInvestigacion[0]?.precio_minimo
                    )
                  }}
                </v-chip>
                <hr class="custom-hr" />
                <v-chip outlined>
                  $
                  {{
                    fixedNumber(
                      investigacion?.data?.montosInvestigacion[0]?.precio_maximo
                    )
                  }}</v-chip
                >
              </v-col>
            </v-row>
            <DataTable
              :headers="headers"
              :items="investigacion?.data?.solicitudes"
              v-models:select="per_page_analisis"
              v-models:pagina="page_analisis"
              :total_registros="total_rows_analisis"
              @paginar="paginar"
            >
              <template v-slot:[`item.fecha`]="{ item }">
                {{ formatDate(item.fecha) }}
              </template>
              <template v-slot:[`item.precio_promedio`]="{ item }">
                $ {{ fixedNumber(item.precio_promedio) }}
              </template>
            </DataTable>
          </v-col>
          <v-col
            cols="12"
            md="6"
            :class="$vuetify.breakpoint.sm ? 'mt-10' : ''"
          >
            <p
              class="text-h6 mb-0 d-block"
              :class="$vuetify.breakpoint.xs ? 'text-center' : ''"
            >
              Úiltimas compras del estado
            </p>
            <v-row>
              <v-col cols="12">
                <p block class="text-center mb-0 secondary--text">
                  Precio promedio de mercado
                </p>
                <p block class="text-h6 secondary--text mb-0 mt-0 text-center">
                  $
                  {{
                    fixedNumber(
                      compras?.data?.montosCompras[0]?.precio_promedio
                    )
                  }}
                </p>
              </v-col>
              <v-col cols="12" class="d-flex justify-center mb-0">
                <v-chip outlined>
                  $
                  {{
                    fixedNumber(compras?.data.montosCompras[0].precio_minimo)
                  }}
                </v-chip>
                <hr class="custom-hr" />
                <v-chip outlined>
                  $
                  {{
                    fixedNumber(compras?.data.montosCompras[0].precio_maximo)
                  }}
                </v-chip>
              </v-col>
            </v-row>
            <DataTable
              :headers="headers2"
              :items="compras?.data?.compras"
              v-models:select="per_page_compra"
              v-models:pagina="page_compra"
              :total_registros="total_rows_compra"
              @paginar="paginar2"
            >
              <template v-slot:[`item.fecha`]="{ item }">
                {{ formatDate(item.fecha) }}
              </template>
              <template v-slot:[`item.precio_promedio`]="{ item }">
                $ {{ fixedNumber(item.precio_promedio) }}
              </template>
            </DataTable>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="mt-10">
      <v-col cols="12">
        <v-btn class="mt-5" @click="$router.go(-1)" text color="#404a7a"
          >Volver</v-btn
        >
      </v-col>
    </v-row>
  </section>
</template>

<script>
import DataTable from "@/components/DataTableComponent.vue";
import { mapState } from "vuex";
export default {
  name: "ReporteComparacionPrecio",
  components: {
    DataTable,
  },
  data: () => ({
    ganadorEncabezado: null,
    id_sub_proceso: null,
    id_obs: null,
    investigacion: [],
    compras: [],
    page_analisis: 1,
    per_page_analisis: 4,
    total_rows_analisis: 0,
    page_compra: 1,
    per_page_compra: 4,
    total_rows_compra: 0,

    headers: [
      {
        text: "Solicitud",
        align: "start",
        sortable: false,
        value: "codigo",
        width: "40%",
      },
      { text: "Fecha", value: "fecha" },
      { text: "Precio promedio ($)", value: "precio_promedio" },
    ],
    headers2: [
      {
        text: "Contrato",
        align: "start",
        sortable: false,
        value: "numero_interno",
        width: "40%",
      },
      { text: "Fecha", value: "fecha" },
      { text: "Precio promedio ($)", value: "precio_promedio" },
    ],
  }),
  methods: {
  
      sendItem(id_subproceso, id_obs) {
      this.id_subproceso = id_subproceso;
      this.id_obs = id_obs;

      this.$router.push({
        name: "oferta-presentada",
        params: { id_subproceso: this.id_subproceso, id_obs: this.id_obs },
      });
      // alert("ID Seleccionado: " + this.id_obs);
    },
  
    async getGanadorLicitacion() {
      const { data } = await this.services.Reporteria.getGanadorLicitacion(
        this.id_sub_proceso
      );
      this.ganadorEncabezado = data;
    },
    async getAnalisisGanadorr() {
      let params = {
        page: this.page_analisis,
        per_page: this.per_page_analisis,
      };
      const resp = await this.services.Reporteria.getAnalisisGanador(
        // ESTE ID ESTA QUEMADO DADO QUE NO HAY UNA VISTA PADRE QUE LA MANDE
        this.id_obs,
        this.id_sub_proceso,
        params
      );
      this.investigacion = resp; 
      this.total_rows_analisis = Number(resp.headers.total_rows);
    },
    async getComprasGanador() {
      let params = {
        page: this.page_compra,
        per_page: this.per_page_compra,
      };
      const res = await this.services.Reporteria.getComprasGanador(
        // ESTE ID ESTA QUEMADO DADO QUE NO HAY UNA VISTA PADRE QUE LA MANDE
        this.id_obs,
        params
      );
      this.compras = res;
      this.total_rows_compra = Number(res.headers.total_rows);
    },
    paginar(filtros) {
      const { cantidad_por_pagina, pagina } = filtros;
      this.page_analisis = pagina;
      this.per_page_analisis = cantidad_por_pagina;
      this.getAnalisisGanadorr();
    },
    paginar2(filtros) {
      const { cantidad_por_pagina, pagina } = filtros;
      this.page_compra = pagina;
      this.per_page_compra = cantidad_por_pagina;
      this.getComprasGanador();
    },
  },
  computed: {
    ...mapState("procesoCompraUACI", [
      "itemsGenerales"
    ]),
    fixedNumber() {
      return (value) => {
        return Number(value).toFixed(2);
      };
    },
    formatDate() {
      return (value) => {
        return this.$moment(value).format("DD/MM/YYYY");
      };
    },
  },
  created() {
    this.id_sub_proceso = this.$route.params.id_subproceso;
    this.id_obs = this.$route.params.id_obs;
    this.getGanadorLicitacion();
    this.getAnalisisGanadorr();
    this.getComprasGanador();
  },
};
</script>

<style scoped>
.custom-hr {
  width: 100px;
  margin-top: 15px;
  color: "secondary";
}
</style>
